<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.income_product") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
          </div>
          <div>
            <el-select
              size="mini"
              @clear="getAll()"
              v-model="warehouseId"
              clearable
              :placeholder="$t('message.warehouses_0')"
            >
              <el-option
                v-for="item in warehouses"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
        >
          <export-excel
            v-can="'patients.excel'"
            class="btn excel_btn"
            :data="list"
            :fields="excel_fields"
            @fetch="controlExcelData()"
            worksheet="Пациенты "
            name="Пациенты.xls"
          >
            <el-button size="mini" icon="el-icon-document-delete">
              Excel
            </el-button>
          </export-excel>
          <crm-column-settings
            :columns="columns"
            :modelName="'remainders'"
            @c-change="updateColumn"
          ></crm-column-settings>
        </div>
      </div>
      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0 bg-white"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <th v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.name.show">{{ columns.name.title }}</th>
            <th v-if="columns.category.show">{{ columns.category.title }}</th>
            <th v-if="columns.code.show">{{ columns.code.title }}</th>
            <th v-if="columns.received.show">{{ columns.received.title }}</th>
            <th v-if="columns.measurement.show">
              {{ columns.measurement.title }}
            </th>
            <th v-if="columns.warehouse_id.show">
              {{ columns.warehouse_id.title }}
            </th>
            <th v-if="columns.user.show">{{ columns.user.title }}</th>
            <th v-if="columns.created_at.show">
              {{ columns.created_at.title }}
            </th>
            <th v-if="columns.settings.show">{{ columns.settings.title }}</th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              ></el-input>
            </th>
            <th v-if="columns.category.show">
              <categories v-model="filterForm.category_id"></categories>
            </th>
            <th v-if="columns.code.show">
              <categories v-model="filterForm.code"></categories>
            </th>
            <th v-if="columns.received.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.received"
                :placeholder="columns.received.title"
                disabled
              ></el-input>
            </th>
            <th v-if="columns.measurement.show">
              <measurements v-model="filterForm.measurement_id"></measurements>
            </th>
            <th v-if="columns.warehouse_id.show"></th>
            <th v-if="columns.user.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.user"
                :placeholder="columns.user.title"
              ></el-input>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                type="date"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                v-model="filterForm.created_at"
                size="mini"
                clearable
                :placeholder="columns.created_at.title"
              ></el-date-picker>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="product in list"
            :key="product.id"
            class="cursor-pointer"
            :style="product.user ? 'background:#FFFFFF' : 'background:#85A8FF;'"
          >
            <td v-if="columns.id.show">{{ product.id }}</td>
            <td v-if="columns.name.show">{{ product.product?  product.product.name:'' }}</td>
            <td v-if="columns.category.show">
              {{  product.product ? product.product.category.name : '' }}
            </td>
            <td v-if="columns.code.show">
              {{  product.product && product.product.code ? product.product.code : "" }}
            </td>
            <td v-if="columns.received.show">
              {{ product.received ? product.received : "0" | formatMoney }}
            </td>
            <td v-if="columns.measurement.show">
              {{  product.product ? product.product.main_measurement.name : '' }}
            </td>
            <td v-if="columns.warehouse_id.show">
              {{ product.warehouse ? product.warehouse.name : "" }}
            </td>
            <td v-if="columns.user.show">
              {{
                product.user
                  ? product.user.name + " " + product.user.surname
                  : ""
              }}
            </td>
            <td v-if="columns.created_at.show">{{ product.created_at }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <el-button
                v-if="product.user"
                size="small"
                type="success"
                icon="el-icon-check"
                disabled
                @click="accept(product)"
                round
                >{{ $t("message.confirmed") }}</el-button
              >
              <el-button
                v-else
                size="small"
                type="primary"
                @click="accept(product)"
                round
                >{{ $t("message.confirm") }}</el-button
              >
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import categories from "@/components/inventory-select/categories";
import measurements from "@/components/inventory-select/measurements";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import { i18n } from "@/utils/i18n";
import {formatMoney} from "@/filters";

export default {
  mixins: [list, drawer],
  data() {
    return {
      warehouseId: "",
      excel_fields: {},
      loadingData: false,
      selectedItem: null,
      drawer: {
        warehouseDetails: {
          name: "warehouses",
          status: false,
          component: "warehouseDetails",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "warehouseProducts/list",
      columns: "remainders/columnsForStuckBunk",
      pagination: "warehouseProducts/pagination",
      filter: "remainders/filter",
      sort: "remainders/sort",
      warehouses: "warehouses/userinventory",
    }),
  },
  async created() {},
  async mounted() {
    this.controlExcelData();
    if (this.warehouses && this.warehouses.length === 0) {
      this.getInventory();
    }
  },
  watch: {
    warehouseId: {
      handler: function (val) {
        const query = {
          ...this.filter,
          ...this.pagination,
          ...this.sort,
          warehouse_id: val,
        };
        if (!this.loadingData) {
          this.loadingData = true;
          this.getRemainders(query)
            .then((response) => {
              this.loadingData = false;
            })
            .catch((error) => {
              this.loadingData = false;
            });
        }
      },
      deep: true,
    },
  },
  methods: {
    formatMoney,
    ...mapActions({
      getRemainders: "warehouseProducts/index",
      acceptProd: "warehouseProducts/acceptProd",
      updateSort: "remainders/updateSort",
      updateFilter: "remainders/updateFilter",
      updateColumn: "remainders/updateColumnForStock",
      updatePagination: "warehouseProducts/updatePagination",
      showRemainder: "remainders/show",
      empty: "remainders/empty",
      delete: "remainders/destroy",
      refresh: "remainders/refreshData",
      getInventory: "warehouses/userinventory",
    }),
    accept(model) {
      this.$confirm(
        i18n.t("message.do_you_want_confirm") +
          model.received +
          " " +
          model.product.name +
          " " +
          model.id,
        i18n.t("message.warning"),
        {
          confirmButtonText: i18n.t("message.yes"),
          cancelButtonText: i18n.t("message.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          if (!this.loadingData) {
            this.loadingData = true;
            const payload = { warehouse_product_id: model.id };
            this.acceptProd(payload)
              .then((res) => {
                this.loadingData = false;
                this.fetchData();
              })
              .catch((err) => {
                this.loadingData = false;
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.cancel"),
          });
        });
    },
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        warehouse_id: this.warehouseId,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.getRemainders(query)
          .then((response) => {
            this.loadingData = false;
          })
          .catch((error) => {
            this.loadingData = false;
          });
      }
    },
    getAll() {
      this.fetchData();
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    details(model) {
      this.selectedItem = model;
      this.drawer.warehouseDetails.status = true;
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
    },
  },
};
</script>
